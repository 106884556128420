html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: "Montserrat", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* Margin bottom by footer height */
  margin-bottom: 440px;
  width: 100vw;
  overflow-x: hidden;
  min-height: 90vh;
}
body .main_container {
  padding: 60px 15px 0;
}
body .contanier {
  overflow-x: hidden;
}

.first-container {
  margin-top: 95px;
}

.last-container {
  padding-bottom: 100px;
}

main {
  margin-top: 56px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px 0;
  /* Set the fixed height of the footer here */
  height: 440px;
  background-color: #f39634;
  min-height: 310px;
  color: #bed8f3;
}
.footer .items-cont {
  padding: 25px 10px;
}
.footer a.nav-link {
  color: #bed8f3;
}
.footer a.nav-link:hover {
  color: #fff;
}
.footer .footer-info {
  font-size: 1.2em;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: white;
}
.footer .footer-info ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer .footer-info li > a,
.footer .footer-info p {
  background: transparent;
  border: 0;
  color: #ffffff;
  font-weight: 300;
  opacity: 0.7;
  font-size: 80%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
.footer .social_media a {
  color: #fff;
}
.footer .footer-info h3,
.footer .footer-info h3 a {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
}
.footer .bottom_footer {
  width: 100%;
  background: #39393b;
  padding: 20px 0;
}
.footer .bottom_footer .container {
  display: flex;
  justify-content: space-between;
}
.footer .bottom_footer a {
  color: #e6e6e6;
  font-size: 25px;
  margin-left: 15px;
}
.footer .bottom_footer .legal a {
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.display_none {
  display: none;
}

.bee_track {
  width: 100%;
  background-color: #fbca26;
}

.bee_track_img {
  width: 100%;
  background-color: #00aec3;
}

@media (max-width: 575px) {
  .first-container {
    margin-top: 66px;
  }
  .footer .bottom_footer .container {
    flex-direction: column;
    text-align: center;
  }
  .footer .bottom_footer .container .legal {
    display: flex;
    flex-direction: column;
  }
}
.top_nav {
  background-color: #f8cf00;
}
.top_nav .container {
  display: flex;
  justify-content: space-between;
}
.top_nav a,
.top_nav span,
.top_nav button {
  color: #262627;
  font-size: 12px;
  margin-right: 10px;
  text-decoration: none;
  padding: 0;
}
.top_nav .btn {
  text-transform: none;
}

.main_navbar {
  background-color: white;
  display: block;
  -webkit-transition: all 0.4s; /* Safari */
  transition: all 0.4s;
  top: 24px;
}
.main_navbar a, button .main_navbar:not(.btn-light) {
  color: white;
}
.main_navbar a:hover, button .main_navbar:not(.btn-light):hover {
  color: #cccccc;
  background-color: rgba(255, 255, 255, 0);
}
.main_navbar a:focus, button .main_navbar:not(.btn-light):focus {
  outline: 0;
}
.main_navbar a.disabled,
.main_navbar a .navbar-brand, button .main_navbar:not(.btn-light).disabled,
button .main_navbar:not(.btn-light) .navbar-brand {
  height: auto;
}
.main_navbar .navbar-brand img {
  max-width: 200px;
}
.main_navbar.top {
  top: 0;
}
.main_navbar.invert a:not(.btn-light),
.main_navbar.invert button:not(.btn-light) {
  color: #f8cf00;
}
.main_navbar.invert a:not(.btn-light):hover,
.main_navbar.invert button:not(.btn-light):hover {
  color: rgb(146, 121.8629032258, 0);
  background-color: rgba(255, 255, 255, 0);
}
.main_navbar.invert a:not(.btn-light):focus,
.main_navbar.invert button:not(.btn-light):focus {
  outline: 0;
}
.main_navbar .navbar_items a.navbar-brand,
.main_navbar .navbar_items button.navbar-brand {
  color: #262627;
  font-size: clamp(10px, 1.1vw, 16px);
}
.main_navbar .navbar_items a.navbar-brand.alto_valor,
.main_navbar .navbar_items button.navbar-brand.alto_valor {
  color: white;
  border-radius: 10px 10px 0 0;
  background: #ed3237;
  padding: 5px 10px;
}
.main_navbar .navbar_items a.navbar-brand.alto_valor i,
.main_navbar .navbar_items button.navbar-brand.alto_valor i {
  color: #ffd700;
}
.main_navbar .navbar_items a.navbar-brand.eco_friendly,
.main_navbar .navbar_items button.navbar-brand.eco_friendly {
  color: white;
  border-radius: 10px 10px 0 0;
  background: #57aa24;
  padding: 5px 10px;
}
.main_navbar .navbar_items a.navbar-brand.eco_friendly i,
.main_navbar .navbar_items button.navbar-brand.eco_friendly i {
  color: #ffd700;
}

.decasajuarez .navbar-brand img,
.decasa .navbar-brand img,
.decasahermosillo .navbar-brand img {
  max-width: 90px;
  margin-right: 30px;
  margin-left: 30px;
}

.idecasa .navbar-brand img {
  max-width: 120px;
  margin-right: 30px;
  margin-left: 30px;
}

.nav-link.disabled {
  color: lighter(#f8cf00, 20) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.search_bar_main {
  background: #fff;
  width: 80%;
  max-width: 680px;
  margin: 0 auto;
  height: 42px;
  margin-top: 11px;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.search_bar_main i.search_bar_search_icon {
  height: 100%;
  line-height: 42px;
  float: left;
  width: 52px;
  text-align: center;
  color: rgba(68, 68, 68, 0.5);
}
.search_bar_main input#search_bar_input {
  height: 42px;
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  background: transparent;
  width: calc(100% - 52px);
  outline: none;
  padding-right: 32px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  font-weight: lighter;
}
.search_bar_main i.clear_search_bar_field {
  position: absolute;
  right: 12px;
  top: 14px;
  color: rgba(189, 189, 189, 0.84);
  display: none;
  font-size: 15px;
}
.search_bar_main i.clear_search_bar_field:hover {
  cursor: pointer;
}

.footer .app {
  background-color: #f8cf00;
  height: 70px;
  position: fixed;
  bottom: 0;
  z-index: 1030;
  width: 100%;
}
.footer .app a,
.footer .app button {
  color: rgb(132.4224137931, 132.4224137931, 136.5775862069);
}
.footer .app a.active,
.footer .app button.active {
  color: #39393b;
}

.footerCta {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  z-index: 1030;
}
.footerCta.unactive {
  bottom: -500px;
}

.products_menu, .conoce_menu {
  position: absolute;
  max-height: 80vh;
  overflow-y: scroll;
  z-index: 1060;
  background-color: #fff;
  top: 100%;
  border-radius: 0 0 20px 20px;
  width: 100%;
  border: 1px solid #eeeeee;
  border-top: 3px solid #f39634;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
}
.products_menu li, .conoce_menu li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.products_menu > ul, .conoce_menu > ul {
  flex: 1 1;
  border-right: 1px solid #e6e6e6;
  margin: 0;
  padding: 0;
}
.products_menu > ul li, .conoce_menu > ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px 15px;
}
.products_menu > ul li:hover, .conoce_menu > ul li:hover {
  background-color: #eeeeee;
  cursor: pointer;
}
.products_menu > ul li:hover > a, .conoce_menu > ul li:hover > a {
  font-size: bold;
  color: #262627;
  text-decoration: none;
}
.products_menu > ul li.active, .conoce_menu > ul li.active {
  background-color: #eeeeee;
  cursor: pointer;
}
.products_menu > ul li.active > a, .conoce_menu > ul li.active > a, .products_menu > ul li.active > button, .conoce_menu > ul li.active > button {
  font-size: bold;
  color: #262627;
  text-decoration: none;
}
.products_menu > ul li.active > a i, .conoce_menu > ul li.active > a i, .products_menu > ul li.active > button i, .conoce_menu > ul li.active > button i {
  color: #262627;
}
.products_menu > ul li a, .conoce_menu > ul li a {
  color: #262627;
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.products_menu > ul li a i, .conoce_menu > ul li a i {
  font-size: 8px;
  color: white;
}
.products_menu .menu_items, .conoce_menu .menu_items {
  flex: 3 1;
  text-align: left;
  padding: 15px;
}
.products_menu .menu_items .childs, .conoce_menu .menu_items .childs {
  margin-bottom: 15px;
  margin-bottom: 15px;
  display: inline-block;
  width: 25%;
  vertical-align: top;
}
.products_menu .menu_items .childs > a, .conoce_menu .menu_items .childs > a {
  text-align: left;
  color: #262627;
  font-weight: bold;
  font-size: 15px;
  padding: 0 15px;
}
.products_menu .menu_items .childs ul, .conoce_menu .menu_items .childs ul {
  margin: 0;
  padding: 0 15px;
}
.products_menu .menu_items .childs ul a, .conoce_menu .menu_items .childs ul a {
  text-align: left;
  color: #262627;
  display: block;
  padding: 2px 0;
  margin: 0;
  font-size: 15px;
}

.conoce_menu {
  width: 250px;
  left: 375px;
}

.mob_nav {
  position: fixed;
  transition: left 0.2s ease-in-out;
  left: -80%;
  top: 0;
  bottom: 0;
  background-color: #e6e6e7;
  width: 80%;
  overflow: hidden;
}
.mob_nav.active {
  left: 0;
}
.mob_nav .header {
  height: 15%;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.mob_nav .header a {
  color: #39393b;
}
.mob_nav .header i {
  font-size: 40px;
  margin-right: 15px;
}
.mob_nav .header span * {
  display: block;
}
.mob_nav .body {
  padding-top: 15px;
  height: 85%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}
.mob_nav .body .mob_nav_items a,
.mob_nav .body .mob_nav_items button {
  display: block;
  padding: 15px;
  color: #262627;
  font-size: 17px;
}
.mob_nav .body .mob_nav_items ul {
  padding: 0;
}
.mob_nav .body .mob_nav_items ul li {
  list-style: none;
}
.mob_nav .body .mob_level {
  position: absolute;
  left: 100%;
  transition: left 0.5s ease-in-out;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #e6e6e7;
  padding-top: 15px;
}
.mob_nav .body .mob_level.active {
  left: 0;
}
.mob_nav .body .mob_level .back_btn {
  font-size: 13px;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
}
.mob_nav .body .mob_level .childs {
  margin-bottom: 15px;
}
.mob_nav .body .mob_level .childs > a {
  text-align: left;
  color: #262627;
  font-weight: bold;
  font-size: 13px;
  padding: 5px 15px;
}
.mob_nav .body .mob_level .childs ul {
  margin: 0;
  padding: 0 15px;
}
.mob_nav .body .mob_level .childs ul a {
  text-align: left;
  color: #9b9b9b;
  display: block;
  padding: 10px 0;
  margin: 0;
  font-size: 14px;
}

.search_results_container {
  position: relative;
}
.search_results_container .search_results {
  position: absolute;
  background-color: #fff;
  border: 0 0 10px 10px;
  border: 1px solid #e6e6e6;
  min-height: 50px;
  max-height: 70vh;
  overflow-y: scroll;
  width: 95%;
  padding: 0;
  z-index: 1040;
}
.search_results_container .search_results .search_item {
  display: flex;
  padding: 10px 20px;
  margin: 0;
  position: relative;
}
.search_results_container .search_results .search_item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.search_results_container .search_results .search_item a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.search_results_container .search_results .search_item img {
  width: 70px;
  border-radius: 10px;
  margin-right: 20px;
}
.search_results_container .search_results .search_item .desc > * {
  margin: 0;
}
.search_results_container .search_results .search_item .desc h4 {
  font-size: 15px;
}
.search_results_container .search_results .search_item .desc p {
  font-size: 13px;
}
.search_results_container .search_results .no_results {
  width: 100%;
  padding-top: 20px;
  font-size: 15px;
  color: #333;
  text-align: center;
}
.search_results_container .loading {
  text-align: center;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_results_container .loading > div div {
  background-color: #f39634;
  opacity: 0.8;
}

@media (max-width: 575px) {
  .main_navbar {
    top: 0;
    z-index: 1030;
  }
  .nav_container img {
    width: 150px;
  }
  .mob_search_bar {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .search_results_container .search_results .search_item {
    width: auto;
    height: 80px;
  }
}
.text-primary {
  color: #f8cf00 !important;
}

.btn-primary {
  color: white !important;
  background-color: #f8cf00 !important;
  outline-color: #f8cf00;
}
.btn-primary:hover {
  background-color: rgb(242.9, 202.7431451613, 0) !important;
  color: white !important;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  color: #9b9b9b;
  font-size: 0.7em;
}
.breadcrumb a {
  color: #9b9b9b;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #e6e6e6;
  content: ">";
}
.breadcrumb .breadcrumb-item:hover {
  cursor: pointer;
}
.breadcrumb .breadcrumb-item.active {
  color: #3e78b2;
  font-weight: bold;
}

ul {
  list-style: none;
  padding-left: 0;
}

.alert p {
  margin: 0;
}
.alert p i {
  margin-right: 10px;
}

/* -- Main style ------------------------------------ */
.btn {
  font-family: "Montserrat", "Roboto", "Helvetica Neue, Helvetica, Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: inherit;
}

.btn-default,
.btn-link {
  color: rgba(0, 0, 0, 0.87);
}

/* -- Buttons style ------------------------------------ */
.btn {
  outline-offset: 0;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
}
.btn:focus, .btn:active, .btn.active, .btn:active:focus, .btn.active:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

/* -- Buttons types -------------------------------- */
.btn-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.btn-raised:active, .btn-raised.active, .btn-raised:active:focus, .btn-raised.active:focus {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.btn-raised:focus {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* -- Material Shadows -------------------------------- */
.material-shadows, .payment_section .btn_rich_container .btn_rich, .alto_valor_contanier .container, .main_navbar .navbar_items a.navbar-brand.alto_valor,
.main_navbar .navbar_items button.navbar-brand.alto_valor, .main_navbar .navbar_items a.navbar-brand.eco_friendly,
.main_navbar .navbar_items button.navbar-brand.eco_friendly,
.material-shadows_mov {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.material-shadows:active, .payment_section .btn_rich_container .btn_rich:active, .alto_valor_contanier .container:active, .main_navbar .navbar_items a.navbar-brand.alto_valor:active,
.main_navbar .navbar_items button.navbar-brand.alto_valor:active, .main_navbar .navbar_items a.navbar-brand.eco_friendly:active,
.main_navbar .navbar_items button.navbar-brand.eco_friendly:active, .material-shadows:focus, .payment_section .btn_rich_container .btn_rich:focus, .alto_valor_contanier .container:focus, .main_navbar .navbar_items a.navbar-brand.alto_valor:focus,
.main_navbar .navbar_items button.navbar-brand.alto_valor:focus, .main_navbar .navbar_items a.navbar-brand.eco_friendly:focus,
.main_navbar .navbar_items button.navbar-brand.eco_friendly:focus,
.material-shadows_mov:active,
.material-shadows_mov:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.54);
}

.btn.btn-circle {
  padding: 0;
  border-radius: 50%;
}

/* -- Buttons colors -------------------------------- */
.btn-default,
.dropdown-toggle.btn-default {
  background-color: #fff;
}
.btn-default:hover,
.dropdown-toggle.btn-default:hover {
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-default:active, .btn-default.active,
.dropdown-toggle.btn-default:active,
.dropdown-toggle.btn-default.active {
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-default:focus,
.dropdown-toggle.btn-default:focus {
  background-color: #cccccc;
}
.btn-default:disabled, .btn-default.disabled, .btn-default[disabled],
.dropdown-toggle.btn-default:disabled,
.dropdown-toggle.btn-default.disabled,
.dropdown-toggle.btn-default[disabled] {
  background-color: rgb(178.5, 178.5, 178.5);
}
.btn-default .ink,
.dropdown-toggle.btn-default .ink {
  background-color: rgb(183.6, 183.6, 183.6);
}

.btn-flat.btn-default {
  color: #212121;
  background-color: transparent;
}
.btn-flat.btn-default:hover {
  color: rgb(20.25, 20.25, 20.25);
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-flat.btn-default:active, .btn-flat.btn-default.active {
  color: rgb(2.4, 2.4, 2.4);
  background-color: #cccccc;
}
.btn-flat.btn-default:focus {
  color: #000;
  background-color: #cccccc;
}
.btn-flat.btn-default .ink {
  background-color: rgb(127.5, 127.5, 127.5);
}

.btn-primary,
.dropdown-toggle.btn-primary {
  background-color: #5677fc;
}
.btn-primary:hover,
.dropdown-toggle.btn-primary:hover {
  background-color: #4e6cef;
}
.btn-primary:active, .btn-primary.active,
.dropdown-toggle.btn-primary:active,
.dropdown-toggle.btn-primary.active {
  background-color: #4e6cef;
}
.btn-primary:focus,
.dropdown-toggle.btn-primary:focus {
  background-color: #455ede;
}
.btn-primary:disabled, .btn-primary.disabled, .btn-primary[disabled],
.dropdown-toggle.btn-primary:disabled,
.dropdown-toggle.btn-primary.disabled,
.dropdown-toggle.btn-primary[disabled] {
  background-color: rgb(178.5, 178.5, 178.5);
}
.btn-primary .ink,
.dropdown-toggle.btn-primary .ink {
  background-color: #3b50ce;
}

.btn-flat.btn-primary {
  color: #5677fc;
  background-color: transparent;
}
.btn-flat.btn-primary:hover {
  color: #4e6cef;
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-flat.btn-primary:active, .btn-flat.btn-primary.active {
  color: #455ede;
  background-color: #cccccc;
}
.btn-flat.btn-primary:focus {
  color: #3b50ce;
  background-color: #cccccc;
}
.btn-flat.btn-primary .ink {
  background-color: rgb(127.5, 127.5, 127.5);
}

.btn-success,
.dropdown-toggle.btn-success {
  background-color: #259b24;
}
.btn-success:hover,
.dropdown-toggle.btn-success:hover {
  background-color: #0a8f08;
}
.btn-success:active, .btn-success.active,
.dropdown-toggle.btn-success:active,
.dropdown-toggle.btn-success.active {
  background-color: #0a8f08;
}
.btn-success:focus,
.dropdown-toggle.btn-success:focus {
  background-color: #0a7e07;
}
.btn-success:disabled, .btn-success.disabled, .btn-success[disabled],
.dropdown-toggle.btn-success:disabled,
.dropdown-toggle.btn-success.disabled,
.dropdown-toggle.btn-success[disabled] {
  background-color: rgb(178.5, 178.5, 178.5);
}
.btn-success .ink,
.dropdown-toggle.btn-success .ink {
  background-color: #056f00;
}

.btn-flat.btn-success {
  color: #259b24;
  background-color: transparent;
}
.btn-flat.btn-success:hover {
  color: #0a8f08;
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-flat.btn-success:active, .btn-flat.btn-success.active {
  color: #0a7e07;
  background-color: #cccccc;
}
.btn-flat.btn-success:focus {
  color: #056f00;
  background-color: #cccccc;
}
.btn-flat.btn-success .ink {
  background-color: rgb(127.5, 127.5, 127.5);
}

.btn-info,
.dropdown-toggle.btn-info {
  background-color: #03a9f4;
}
.btn-info:hover,
.dropdown-toggle.btn-info:hover {
  background-color: #039be5;
}
.btn-info:active, .btn-info.active,
.dropdown-toggle.btn-info:active,
.dropdown-toggle.btn-info.active {
  background-color: #039be5;
}
.btn-info:focus,
.dropdown-toggle.btn-info:focus {
  background-color: #0288d1;
}
.btn-info:disabled, .btn-info.disabled, .btn-info[disabled],
.dropdown-toggle.btn-info:disabled,
.dropdown-toggle.btn-info.disabled,
.dropdown-toggle.btn-info[disabled] {
  background-color: rgb(178.5, 178.5, 178.5);
}
.btn-info .ink,
.dropdown-toggle.btn-info .ink {
  background-color: #0277bd;
}

.btn-flat.btn-info {
  color: #03a9f4;
  background-color: transparent;
}
.btn-flat.btn-info:hover {
  color: #039be5;
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-flat.btn-info:active, .btn-flat.btn-info.active {
  color: #0288d1;
  background-color: #cccccc;
}
.btn-flat.btn-info:focus {
  color: #0277bd;
  background-color: #cccccc;
}
.btn-flat.btn-info .ink {
  background-color: rgb(127.5, 127.5, 127.5);
}

.btn-warning,
.dropdown-toggle.btn-warning {
  background-color: #ffc107;
}
.btn-warning:hover,
.dropdown-toggle.btn-warning:hover {
  background-color: #ffb300;
}
.btn-warning:active, .btn-warning.active,
.dropdown-toggle.btn-warning:active,
.dropdown-toggle.btn-warning.active {
  background-color: #ffb300;
}
.btn-warning:focus,
.dropdown-toggle.btn-warning:focus {
  background-color: #ffa000;
}
.btn-warning:disabled, .btn-warning.disabled, .btn-warning[disabled],
.dropdown-toggle.btn-warning:disabled,
.dropdown-toggle.btn-warning.disabled,
.dropdown-toggle.btn-warning[disabled] {
  background-color: rgb(178.5, 178.5, 178.5);
}
.btn-warning .ink,
.dropdown-toggle.btn-warning .ink {
  background-color: #ff8f00;
}

.btn-flat.btn-warning {
  color: #ffc107;
  background-color: transparent;
}
.btn-flat.btn-warning:hover {
  color: #ffb300;
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-flat.btn-warning:active, .btn-flat.btn-warning.active {
  color: #ffa000;
  background-color: #cccccc;
}
.btn-flat.btn-warning:focus {
  color: #ff8f00;
  background-color: #cccccc;
}
.btn-flat.btn-warning .ink {
  background-color: rgb(127.5, 127.5, 127.5);
}

.btn-danger,
.dropdown-toggle.btn-danger {
  background-color: #ff5722;
}
.btn-danger:hover,
.dropdown-toggle.btn-danger:hover {
  background-color: #f4511e;
}
.btn-danger:active, .btn-danger.active,
.dropdown-toggle.btn-danger:active,
.dropdown-toggle.btn-danger.active {
  background-color: #f4511e;
}
.btn-danger:focus,
.dropdown-toggle.btn-danger:focus {
  background-color: #e64a19;
}
.btn-danger:disabled, .btn-danger.disabled, .btn-danger[disabled],
.dropdown-toggle.btn-danger:disabled,
.dropdown-toggle.btn-danger.disabled,
.dropdown-toggle.btn-danger[disabled] {
  background-color: rgb(178.5, 178.5, 178.5);
}
.btn-danger .ink,
.dropdown-toggle.btn-danger .ink {
  background-color: #d84315;
}

.btn-flat.btn-danger {
  color: #ff5722;
  background-color: transparent;
}
.btn-flat.btn-danger:hover {
  color: #f4511e;
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn-flat.btn-danger:active, .btn-flat.btn-danger.active {
  color: #e64a19;
  background-color: #cccccc;
}
.btn-flat.btn-danger:focus {
  color: #d84315;
  background-color: #cccccc;
}
.btn-flat.btn-danger .ink {
  background-color: rgb(127.5, 127.5, 127.5);
}

/* -- Buttons sizes -------------------------------- */
.btn {
  padding: 10px 14px;
}

.btn-lg,
.btn-group-lg > .btn {
  min-width: 122px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3;
}

.btn-sm,
.btn-group-sm > .btn {
  min-width: 64px;
  padding: 4px 12px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-xs,
.btn-group-xs > .btn {
  min-width: 46px;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 1.5;
}

.btn-circle {
  width: 56px;
  height: 56px;
  min-width: 56px;
}
.btn-circle span {
  line-height: 56px;
}

.btn-circle.btn-lg {
  width: 78px;
  height: 78px;
  min-width: 78px;
}
.btn-circle.btn-lg span {
  line-height: 78px;
}

.btn-circle.btn-sm {
  width: 40px;
  height: 40px;
  min-width: 40px;
}
.btn-circle.btn-sm span {
  line-height: 40px;
}

.btn-circle.btn-xs {
  width: 30px;
  height: 30px;
  min-width: 30px;
}
.btn-circle.btn-xs span {
  line-height: 30px;
}

/*-- Button groups --------------------------------- */
.btn-group .btn {
  border-radius: 2px;
}

.btn-group.open .dropdown-toggle {
  outline: 0;
  outline-offset: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: 0;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 0;
}

.btn-group > .btn:focus:hover,
.btn-group-vertical > .btn:focus:hover,
.btn-group > .btn:active:hover,
.btn-group-vertical > .btn:active:hover,
.btn-group > .btn.active:hover,
.btn-group-vertical > .btn.active:hover {
  z-index: 2;
}

/* -- Ripple effect -------------------------------- */
.ripple-effect {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.ink {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  background: #fff;
  opacity: 1;
}

.ink.animate {
  -webkit-animation: ripple 0.5s linear;
  -moz-animation: ripple 0.5s linear;
  -ms-animation: ripple 0.5s linear;
  -o-animation: ripple 0.5s linear;
  animation: ripple 0.5s linear;
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-ms-keyframes ripple {
  100% {
    opacity: 0;
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
    transform: scale(2.5);
  }
}
.text_main {
  color: #f8cf00;
}

.text_default {
  color: #262627;
}

.text_success {
  color: #01e668;
}

.word_break, .display-1, .display-2, .display-3, .display-4, .display-5 {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.underlined {
  border-bottom: 3px solid #f8cf00;
  max-width: 60%;
}

.opacity_0 {
  opacity: 0;
}

.opacity_1 {
  opacity: 0.1;
}

.opacity_2 {
  opacity: 0.2;
}

.opacity_3 {
  opacity: 0.3;
}

.opacity_4 {
  opacity: 0.4;
}

.opacity_5 {
  opacity: 0.5;
}

.opacity_6 {
  opacity: 0.6;
}

.opacity_7 {
  opacity: 0.7;
}

.icon_rounded {
  background-color: #eeeeee;
  color: #262627;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 100%;
}
.icon_rounded i {
  font-size: 20px;
}
.icon_rounded.white {
  background-color: white;
  color: #9b9b9b;
  border: 3px solid #f39634;
}

.btn-cart {
  background-color: #f8cf00;
  color: #39393b !important;
  border-radius: 10px;
}
.btn-cart:hover {
  background-color: rgb(222.5, 185.7157258065, 0) !important;
}
@media screen and (max-width: 768px) {
  .btn-cart.fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.btn-payment {
  display: block;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .btn-payment.fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
}

.btn_whatsaap {
  background-color: #25d366;
  color: white;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 23px;
  align-items: center;
}
.btn_whatsaap:hover {
  color: white;
  background-color: rgb(33.1955645161, 189.3044354839, 91.5120967742) !important;
  text-decoration: none;
}
.btn_whatsaap.fixed {
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 1020;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.btn_share {
  background-color: transparent;
  color: #383a40;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  min-height: 35px;
  border-color: transparent;
}
.btn_share:hover {
  color: black;
  text-decoration: none;
}

.btn-outline-cart {
  border: 1px solid #f8cf00;
  color: #39393b !important;
  border-radius: 10px;
}
.btn-outline-cart:hover {
  background-color: #f8cf00;
}

.btn_lg {
  min-width: 122px;
  padding: 10px 16px;
  line-height: 1.3;
}

.btn-cont-50 .btn {
  width: 45%;
  margin: 5px;
}

.btn-light {
  background-color: #eeeeee;
  color: #262627;
}
.btn-light.search_bar {
  border: 1px solid #e6e6e6;
}

.social .facebook {
  transition: color 0.3s ease-in-out;
}
.social .facebook.colored {
  color: #4267b2;
}
.social .facebook.hover:hover {
  color: #4267b2;
}
.social .facebook.hover_bg:hover {
  background-color: #4267b2;
  color: #ffffff;
}
.social .facebook-f {
  transition: color 0.3s ease-in-out;
}
.social .facebook-f.colored {
  color: #4267b2;
}
.social .facebook-f.hover:hover {
  color: #4267b2;
}
.social .facebook-f.hover_bg:hover {
  background-color: #4267b2;
  color: #ffffff;
}
.social .instagram {
  transition: color 0.3s ease-in-out;
}
.social .instagram.colored {
  color: #c32aa3;
}
.social .instagram.hover:hover {
  color: #c32aa3;
}
.social .instagram.hover_bg:hover {
  background-color: #c32aa3;
  color: #ffffff;
}
.social .youtube {
  transition: color 0.3s ease-in-out;
}
.social .youtube.colored {
  color: #ff0002;
}
.social .youtube.hover:hover {
  color: #ff0002;
}
.social .youtube.hover_bg:hover {
  background-color: #ff0002;
  color: #ffffff;
}
.social .twitter {
  transition: color 0.3s ease-in-out;
}
.social .twitter.colored {
  color: #1da1f2;
}
.social .twitter.hover:hover {
  color: #1da1f2;
}
.social .twitter.hover_bg:hover {
  background-color: #1da1f2;
  color: #ffffff;
}
.social .linkedin {
  transition: color 0.3s ease-in-out;
}
.social .linkedin.colored {
  color: #007bb5;
}
.social .linkedin.hover:hover {
  color: #007bb5;
}
.social .linkedin.hover_bg:hover {
  background-color: #007bb5;
  color: #ffffff;
}

.color_filter {
  width: 30px;
  min-width: auto;
  height: 30px;
  border-radius: 100%;
  background-color: #eeeeee;
  border: 2px solid #eeeeee;
  margin-right: 5px;
}
.color_filter.selected {
  border: 2px solid #262627;
}

.btn_rich_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.btn_rich_container.horizontal {
  flex-direction: column;
}
.btn_rich_container.horizontal .btn_rich {
  margin-bottom: 10px;
  width: 100%;
  min-height: 100px;
  flex-direction: row;
  justify-content: flex-start;
}
.btn_rich_container.horizontal .btn_rich:last-child {
  margin-left: 0;
}
.btn_rich_container.horizontal .btn_rich p:not(:first-child) {
  margin-left: 15px;
}
.btn_rich_container.horizontal .btn_rich .cards_icons {
  margin-right: 15px;
  top: 0;
  right: 0;
  left: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.btn_rich_container.horizontal .btn_rich .cards_icons i {
  margin: 0;
  margin-right: 0;
}
.btn_rich_container.horizontal .btn_rich i {
  margin: 0;
}
.btn_rich_container.horizontal .btn_rich.rappi_btn img {
  width: 250px;
}
.btn_rich_container.horizontal .btn_rich.coppel_btn {
  background-color: #fbdd31;
  color: #2266ae;
  flex-wrap: wrap;
}
.btn_rich_container.horizontal .btn_rich.disabled {
  filter: grayscale(1);
}
.btn_rich_container > * {
  flex: 1 1;
}
.btn_rich_container > *:first-child {
  margin-right: 15px;
}
.btn_rich_container .btn_rich:last-child {
  margin-left: 15px;
}

.btn_rich {
  border: 1px solid #262627;
  position: relative;
  text-align: center;
  border-radius: 5px;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 160px;
}
.btn_rich.small {
  min-height: initial;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
}
.btn_rich:hover {
  cursor: pointer;
}
.btn_rich.active {
  border: 5px solid #3e78b2;
}
.btn_rich p {
  margin: 0;
}
.btn_rich p.text-help {
  color: #9b9b9b;
}
.btn_rich i {
  font-size: xx-large;
  margin-bottom: 20px;
}
.btn_rich .cards_icons {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 80%;
  left: 0;
}
.btn_rich .cards_icons i {
  color: #9b9b9b;
  margin-right: 15px;
}
.btn_rich .cards_icons i:last-child {
  margin-right: 0;
}

.no_focus:focus, .no_focus:active {
  outline: none;
  box-shadow: none;
}

.full_link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hover:hover {
  cursor: pointer;
}

.badge_main {
  background-color: #f8cf00;
}

.rappi_pay_btn {
  background-color: #eb4025;
  border-radius: 15px;
}
.rappi_pay_btn img {
  width: 60%;
}

.brad-5px {
  border-radius: 5px !important;
}

.coppel_btn.active {
  background-color: #fbdd31;
}
.coppel_btn img {
  width: 150px;
}

.atrato_btn.active {
  background-color: #162d4f;
  color: white;
}

.paypal_btn.active {
  background-color: #fac43a;
  color: #3396d4;
}

.online_btn {
  margin-right: 15px !important;
}
.online_btn.active {
  background-color: #33a744;
  color: #f0f0f0;
}

.transfer_btn.active {
  background-color: #f5f5f5;
  color: #333333;
}

.fixed_top {
  position: fixed;
  top: 75px;
  z-index: 1030;
}

.fixed_bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.form-absolute {
  position: absolute;
  width: 100%;
  top: 23px;
  z-index: 10;
}

.fixed_right {
  right: 0;
  left: auto;
}

.z_index_1 {
  z-index: 1000 !important;
}
.z_index_2 {
  z-index: 1020 !important;
}
.z_index_3 {
  z-index: 1030 !important;
}
.z_index_4 {
  z-index: 1040 !important;
}
.z_index_5 {
  z-index: 1050 !important;
}
.z_index_6 {
  z-index: 1060 !important;
}
.z_index_7 {
  z-index: 1070 !important;
}

.min-vh-90 {
  min-height: 90vh !important;
}

.min-vh-80 {
  min-height: 80vh !important;
}

.min-vh-70 {
  min-height: 70vh !important;
}

.animate_all {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@-webkit-keyframes bounce_left {
  0% {
    left: 15px;
  }
  50% {
    left: 5px;
  }
  100% {
    left: 15px;
  }
}
@-webkit-keyframes bounce_right {
  0% {
    right: 15px;
  }
  50% {
    right: 5px;
  }
  100% {
    right: 15px;
  }
}
.bounce_right {
  animation-name: bounce_right;
  animation-duration: 0.7s;
  animation-iteration-count: 2;
  animation-direction: reverse;
  animation-timing-function: ease-in;
  animation-delay: 2s;
}

.floating_anm {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 10px);
  }
  to {
    transform: translate(0, 0px);
  }
}
.fly_away_anm {
  animation-name: fly_away;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@keyframes fly_away {
  from {
    transform: translate(0, 0px);
    opacity: 1;
  }
  to {
    transform: translate(80px, -20px);
    opacity: 0;
  }
}
.pulse_anm {
  animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.95);
    opacity: 0.7;
  }
}
.big_entrance_anm {
  animation-name: big_entrance;
  -webkit-animation-name: big_entrance;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important;
}
.big_entrance_anm.fast {
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
}

@keyframes big_entrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}
@-webkit-keyframes big_entrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}
.animate_notification {
  -webkit-animation: moveOpen 10s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
}

.animate_notification_fast {
  -webkit-animation: moveOpen 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
}

/* Safari and Chrome */
@-webkit-keyframes moveOpen {
  from {
    -webkit-transform: translate(0, 0px);
  }
  10% {
    -webkit-transform: translate(0, 100px);
  }
  80% {
    -webkit-transform: translate(0, 100px);
  }
  to {
    -webkit-transform: translate(0, 0px);
  }
}
.color_pump_elem {
  -webkit-animation: color_pump 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
}

@keyframes color_pump {
  0% {
    background-color: #e8f2f3;
  }
  50% {
    background-color: #b9c4c5;
  }
  100% {
    background-color: #e8f2f3;
  }
}
@-webkit-keyframes color_pump {
  0% {
    background-color: #e8f2f3;
  }
  50% {
    background-color: #b9c4c5;
  }
  100% {
    background-color: #e8f2f3;
  }
}
.loader_top {
  animation-name: horizontal_move;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.loader_info {
  position: fixed;
  bottom: 100px;
  left: 20px;
  min-width: 150px;
  text-align: center;
  border-radius: 5px;
  background-color: #404040;
  color: white;
  min-block-size: 20px;
  padding: 10px;
  font-size: 0.8em;
  animation-name: opacity_Out;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.fade_content {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  animation-name: opacity_In;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes opacity_In {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes opacity_Out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes horizontal_move {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.shake_anim {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
.instagram_feed {
  clear: both;
  height: 430px;
}
.instagram_feed .instagram_item {
  width: 200px;
  height: 200px;
  margin: 1px;
  float: left;
  position: relative;
}
.instagram_feed .instagram_item:first-child {
  width: 400px;
  height: 400px;
}
.instagram_feed .instagram_item a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #e4e4e4;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  display: flex;
}
.instagram_feed .instagram_item a:hover {
  opacity: 1;
}

.backdrop {
  position: fixed;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.2s linear;
  pointer-events: none;
}
.backdrop.active {
  pointer-events: initial;
  opacity: 1;
}

.backdrop_nav {
  position: fixed;
  z-index: 1020;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: none;
}
.backdrop_nav.active {
  display: block;
}

.custom_modal {
  position: fixed;
  z-index: 1050;
  border-radius: 20px;
  background: white;
  top: 5vh;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  transition: all 0.25s linear;
  transform: translateY(-100vh);
  opacity: 0;
  min-height: 200px;
  width: 50%;
  overflow: hidden;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  pointer-events: none;
}
.custom_modal.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: initial;
}
.custom_modal .header {
  background-color: #f8cf00;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.custom_modal .header h3 {
  font-weight: lighter;
  margin: 0;
  color: #333;
  font-size: 18px;
}
.custom_modal .header button {
  position: absolute;
  right: 5px;
  top: 5px;
}
.custom_modal .body {
  padding: 25px;
}
.custom_modal .body h5 {
  color: #262627;
}
.custom_modal .body select {
  max-width: 50%;
  margin: 0 auto;
}
.custom_modal .body p {
  color: #262627;
}
.custom_modal .modal_footer p {
  color: #262627;
}

.product_modal {
  min-height: 90vh;
  width: 90%;
  overflow: hidden;
}
.product_modal .body {
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-template-areas: "reco reco cart " "reco reco cart " "reco reco cta ";
}
.product_modal .body h4 {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 15px;
}
.product_modal .body > div {
  padding: 20px;
}
.product_modal .body .related_products {
  grid-area: reco;
}
.product_modal .body .products {
  grid-area: cart;
}
.product_modal .body .foot {
  border-top: 1px solid #e6e6e6;
  padding-top: 15px;
  grid-area: cta;
}
.product_modal .body .goto_cart .total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.product_modal .body .scroll_container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.full_loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full_loader i {
  font-size: 4em;
}
.full_loader p {
  color: #262627;
}
.full_loader .loader {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #9b9b9b;
  height: 5px;
  overflow: hidden;
  position: relative;
}
.full_loader .loader > div {
  width: 10%;
  position: absolute;
  height: 5px;
  background-color: #3e78b2;
}

.save_order_modal .body {
  padding: 20px 10%;
  text-align: center;
}

@media (max-width: 575px) {
  .custom_modal {
    width: 90%;
  }
  .custom_modal .body select {
    max-width: 100%;
  }
  .product_modal {
    max-height: 90vh;
  }
  .product_modal .body {
    display: block;
    height: 90vh;
    overflow: scroll;
    padding-bottom: 180px;
  }
  .product_modal .body .border-right {
    border: none !important;
  }
  .product_modal .body > div {
    padding: 0px;
  }
  .product_modal .body .foot {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    left: 0;
    right: 0;
    padding: 20px;
  }
  .backdrop_nav {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .backdrop_nav.search {
    z-index: 1000;
  }
}
.product_related {
  border-bottom: 1px solid #dee2e6;
  padding: 15px 0;
}
.product_related:last-child {
  border-bottom: none;
}
.product_related h3 {
  line-height: 1.1;
  font-size: 18px;
  font-weight: normal;
}
.product_related h3 a {
  color: #3e78b2;
}
.product_related .price {
  color: #3e78b2;
  font-size: 20px;
}

.images_cont {
  position: relative;
}
.images_cont .nav_arrows {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: -15px;
  right: -15px;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}
.images_cont .nav_arrows button {
  pointer-events: initial;
  color: #9b9b9b;
  opacity: 1;
  transition: opacity 0.3s;
}
.images_cont .nav_arrows button:disabled {
  opacity: 0;
  pointer-events: none;
}

.products_list h1.underlined {
  font-size: 28px;
  max-width: 90%;
  padding-bottom: 5px;
}

.product_cart img {
  max-height: 100px;
}
.product_cart .img_container {
  width: 100%;
  height: 100px;
  background-size: contain;
  background-clip: border-box;
  background-repeat: no-repeat;
  background-position: center;
}
.product_cart h5 {
  line-height: 1.1;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  color: #3e78b2;
}
.product_cart small {
  color: #9b9b9b;
}
.product_cart .price {
  color: #3e78b2;
}
.product_cart .price .regluar_price {
  display: block;
  font-size: 80%;
  color: #9b9b9b;
}
.product_cart .cart_price {
  font-size: 1.3em;
  font-weight: lighter;
}
.product_cart .details {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: lighter;
}
.product_cart .details b {
  color: #262627;
}

.product_block_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product_block {
  max-width: 50%;
  margin-bottom: 20px;
}
.product_block .brand {
  display: block;
  color: #9b9b9b;
  font-size: 14px;
  font-weight: lighter;
}
.product_block .desc {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
}
.product_block .desc > * {
  flex-grow: 1;
}
.product_block .price {
  display: flex;
  font-weight: 100;
  color: #3e78b2;
  font-size: 25px;
  align-items: center;
}
.product_block .price span:last-child {
  color: #9b9b9b;
  font-size: 18px;
  margin-left: 5px;
}

.discount_img {
  position: absolute;
  text-align: center;
  pointer-events: none;
  z-index: 300;
  max-width: 90%;
  left: 50%;
  transform: translate(-50%);
}

.product_block_list, .product_block_alto_valor {
  width: 255px;
  position: relative;
}
.product_block_list .brand, .product_block_alto_valor .brand {
  display: block;
  color: #9b9b9b;
  font-size: 14px;
  font-weight: lighter;
}
.product_block_list .desc, .product_block_alto_valor .desc {
  display: flex;
  flex-direction: column;
}
.product_block_list .desc > *, .product_block_alto_valor .desc > * {
  flex-grow: 1;
}
.product_block_list .price, .product_block_alto_valor .price {
  font-weight: 100;
  margin: 10px 0;
  color: #3e78b2;
  font-size: 20px;
  align-items: flex-end;
}
.product_block_list .price span.unit, .product_block_alto_valor .price span.unit {
  opacity: 0.7;
  font-size: 70%;
  margin-left: 5px;
}
.product_block_list .price span.regluar_price, .product_block_alto_valor .price span.regluar_price {
  color: #9b9b9b;
  font-size: 20px;
  font-size: 70%;
  display: block;
}
.product_block_list .price span.regluar_price span:first-child, .product_block_alto_valor .price span.regluar_price span:first-child {
  text-decoration: line-through;
}
.product_block_list .surface_data > span, .product_block_alto_valor .surface_data > span {
  display: block;
}
.product_block_list .surface_data > span:not(:last-child)::after, .product_block_alto_valor .surface_data > span:not(:last-child)::after {
  content: none;
}
.product_block_list .surface_info, .product_block_alto_valor .surface_info {
  text-align: center;
}
.product_block_list .surface_info .amount_data, .product_block_alto_valor .surface_info .amount_data {
  display: none;
}

.no_stock {
  text-align: center;
  color: #9b9b9b;
}

.surface_data .badge_main {
  padding: 10px 15px;
  font-size: 13px;
}
.surface_data .badge_main.bold {
  font-weight: 600;
  background-color: #ffed5f;
  font-size: 15px;
}
.surface_data > span {
  font-weight: normal;
}
.surface_data > span:not(:last-child) {
  margin-right: 15px;
}
.surface_data > span span {
  font-size: 12px;
  margin-left: 3px;
  opacity: 0.7;
}

.page-loading {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.page-loading.hero_loader {
  min-height: 362px;
  display: flex;
  align-items: center;
  background-color: #cbcbcb;
}
.page-loading.hero_loader .css-0 div {
  background-color: white;
  opacity: 0.8;
}
.page-loading p {
  color: #9b9b9b;
  margin-top: 20px;
}

.bg_gray {
  background-color: #eeeff0;
}

.empty_jumbo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  color: #9b9b9b;
}
.empty_jumbo p {
  margin: 0;
  margin-top: 10px;
  font-size: 14px;
}
.empty_jumbo h3 {
  font-size: 1.5em;
  margin: 0;
  margin-top: 10px;
  font-weight: normal;
}
.empty_jumbo i {
  display: block;
  font-size: 2em;
}

.alert-slide-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.alert-shake {
  -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
.alert-fixed {
  position: fixed;
  z-index: 1070;
  top: -500px;
  transition: top ease-in-out 0.5s;
  min-width: 500px;
}
.alert-fixed.alert-right {
  right: 30px;
}
.alert-fixed.active {
  top: 40px;
}
.alert-danger {
  background-color: #ff595e;
  border-color: #ff595e;
  color: white;
}
.alert-warning {
  background-color: #ffca3a;
  border-color: #ffca3a;
  color: white;
}

.card_icon {
  background-color: #f5f5f5;
}
.card_icon.selected {
  border: 2px solid #3e78b2;
}
.card_icon p {
  margin: 0;
}
.card_icon small {
  font-size: 14px;
  font-weight: 100;
}
.card_icon.sm .icon_rounded {
  height: 40px;
  width: 40px;
}
.card_icon.sm .icon_rounded i {
  font-size: 15px;
}

.main_background {
  padding: 180px 0 0px;
  overflow: hidden;
  min-height: 99vh;
  margin-top: -80px;
}

.full_bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.parallax {
  background-attachment: fixed;
  background-color: #f8cf00;
  background-blend-mode: soft-light;
}

.bg_transition {
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
}

.features-left {
  position: relative;
  padding-right: 70px;
  text-align: right;
  margin-bottom: 50px;
}
.features-left p {
  color: #9b9b9b;
}
.features-left i {
  position: absolute;
  top: calc(30% - 15px);
  right: 0;
}

.features-right {
  position: relative;
  padding-left: 70px;
  margin-bottom: 50px;
}
.features-right p {
  color: #9b9b9b;
}
.features-right i {
  position: absolute;
  top: calc(30% - 15px);
  left: 0;
}

.get_started {
  background-color: #f8cf00;
  padding: 30px 0;
}

.divider {
  width: 100%;
  height: 10px;
  margin: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.carousel_container {
  position: relative;
}
.carousel_container a,
.carousel_container button {
  position: absolute;
  top: calc(50% - 24px);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.7;
}
.carousel_container a:hover,
.carousel_container button:hover {
  opacity: 1;
}
.carousel_container a.left,
.carousel_container button.left {
  left: 15px;
}
.carousel_container a.right,
.carousel_container button.right {
  right: 15px;
}

.carousel_body {
  position: relative;
  overflow: hidden;
  min-height: 80vh;
}
.carousel_body .carousel_item {
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  position: absolute;
  left: 100%;
  min-height: 80vh;
  width: 100%;
}
.carousel_body .carousel_item.active {
  left: 20px;
}
.carousel_body .carousel_item.not_active {
  left: -100%;
}

.carousel .slide {
  background: transparent !important;
}

.hero_container {
  display: flex;
  align-items: center;
}
.hero_container .full_link {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.hero_container .finder_select {
  margin: 0 auto;
  background-color: rgba(38, 38, 39, 0.7);
  min-height: 40vh;
  max-width: 90%;
  padding: 50px 30px;
  color: white;
}
.hero_container .finder_select h1 {
  color: white;
  text-align: center;
}
.hero_container .finder_select select {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  margin-bottom: 10px;
  -webkit-appearance: none;
  border-radius: 0;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='white' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.hero_container .finder_select select:active {
  color: red;
}
.hero_container .finder_select .btn {
  background-color: #f7f5f1;
}

.featured {
  margin: 15px 0;
  min-height: 90vh;
}
.featured .background_img {
  background-size: cover;
  position: relative;
  background-position: center center;
}
.featured .background_img .bg_type {
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  position: relative;
}
.featured .background_img .bg_type .flag {
  background-color: #ec3137;
  max-width: 80%;
  min-height: 50px;
  display: flex;
  align-items: center;
  text-align: left;
  position: absolute;
  left: -8px;
  top: 20px;
  padding: 0 15px;
}
.featured .background_img .bg_type .flag:before, .featured .background_img .bg_type .flag:after {
  content: "";
  position: absolute;
}
.featured .background_img .bg_type .flag:before {
  height: 0;
  width: 0;
  top: -8.5px;
  left: 0.1px;
  border-bottom: 9px solid black;
  border-left: 9px solid transparent;
}
.featured .background_img .bg_type .flag:after {
  height: 0;
  width: 0;
  right: -14.5px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 15px solid #ec3137;
}
.featured .background_img:hover {
  cursor: pointer;
}
.featured .background_img:hover > .bg_type {
  background-color: rgba(0, 0, 0, 0.2);
}

.promotions {
  margin: 1rem;
  display: grid;
  justify-content: space-between;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 1060px) {
  .promotions {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 720px) {
  .promotions {
    grid-template-columns: repeat(2, 1fr);
  }
}
.promotions img {
  width: 250px;
  height: 250px;
  background-size: cover;
  position: relative;
  background-position: center center;
  margin-bottom: 5px;
}
@media screen and (max-width: 1060px) {
  .promotions img {
    width: 120px;
    height: 120px;
  }
}
.promotions .regular-price {
  text-decoration: line-through;
}

.best-sellers {
  width: 100%;
}
@media screen and (min-width: 1300px) {
  .best-sellers {
    width: 1300px;
  }
}

.contact_container {
  background-image: url(https://images.unsplash.com/photo-1547149600-a6cdf8fce60c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80);
  background-size: cover;
  color: white;
}
.contact_container .container {
  padding: 40px 0;
}
.contact_container .container p {
  text-transform: capitalize;
}
.contact_container .container p.active {
  border: 2px solid #f8cf00;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 575px) {
  .featured {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "main main" "two tree" "for bott" "one one";
  }
  .featured .background_img {
    margin: 5px;
    text-align: center;
  }
  .featured .background_img:nth-child(1) {
    grid-area: main;
  }
  .featured .background_img:nth-child(2) {
    grid-area: one;
  }
  .featured .background_img:nth-child(3) {
    grid-area: two;
  }
  .featured .background_img:nth-child(4) {
    grid-area: tree;
  }
  .featured .background_img:nth-child(5) {
    grid-area: for;
  }
  .featured .background_img:nth-child(6) {
    grid-area: bott;
  }
  .featured .background_img .bg_type {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .featured .background_img .bg_type .flag {
    position: relative;
    font-size: 14px;
  }
}
.carouselArrow {
  background: rgba(0, 0, 0, 0);
  transition: all 0.25s ease-in;
}
.carouselArrow:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.carouselIndicator {
  border-radius: 100%;
}

.product {
  margin-top: 20px;
}
.product .images {
  text-align: center;
}
.product .images img {
  max-height: 500px;
}
.product .images .BrainhubCarousel__dots img {
  max-height: 100px;
}
.product .right {
  padding: 0 5%;
}
.product .right .desc {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.product .right .desc h1 {
  font-size: 1.5em;
  color: #262627;
}
.product .right .desc p:not(.long-desc) {
  font-size: smaller;
  color: #9b9b9b;
  margin: 0;
}
.product .right .desc p.long-desc {
  margin-top: 15px;
  margin-bottom: 0;
  color: #262627;
  font-size: 18px;
}
.product .right .pricing {
  display: flex;
  align-items: flex-end;
}
.product .right .pricing h2 {
  align-items: center;
  font-weight: 100;
}
.product .right .pricing h2 span {
  text-align: left;
  margin-bottom: 5px;
  width: 100%;
  font-size: 60%;
  color: #9b9b9b;
}
.product .right .pricing h2 span.line {
  text-decoration: line-through;
}
.product .right .pricing .unit {
  margin-left: 10px;
  padding-bottom: 5px;
  opacity: 0.7;
}
.product .images .BrainhubCarousel__dots {
  justify-content: flex-start;
}
.product .images .BrainhubCarousel__dots li {
  width: 100px;
}
.product .images .BrainhubCarousel__dots li button {
  background: transparent;
}

.features-section .nav {
  margin-bottom: 20px;
}
.features-section .nav li button.nav-link {
  border: none;
  border-bottom: 1px solid #fff;
  padding-bottom: 0;
  border-radius: 0;
  background-color: #fff;
}
.features-section .nav li button.nav-link.active {
  border-bottom: 2px solid #f8cf00;
  color: #262627;
}
.features-section .tab-content {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  font-size: 15px;
}
.features-section .tab-content p {
  margin: 0;
}

.product_block_list, .product_block_alto_valor,
.product_block {
  margin-bottom: 15px;
}
.product_block_list img, .product_block_alto_valor img,
.product_block img {
  max-width: 100%;
  text-align: center;
  height: 150px;
}

.product_block_list, .product_block_alto_valor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.alto_valor_contanier {
  background: hsl(205, 46%, 10%);
  background: linear-gradient(90deg, hsl(205, 46%, 10%) 0%, hsl(191, 28%, 23%) 50%, hsl(207, 41%, 27%) 100%);
  background: -moz-linear-gradient(90deg, hsl(205, 46%, 10%) 0%, hsl(191, 28%, 23%) 50%, hsl(207, 41%, 27%) 100%);
  background: -webkit-linear-gradient(90deg, hsl(205, 46%, 10%) 0%, hsl(191, 28%, 23%) 50%, hsl(207, 41%, 27%) 100%);
  padding-bottom: 150px;
}
.alto_valor_contanier .container {
  background: white;
}
.alto_valor_contanier .container .product_block_container {
  padding: 50px 20px;
}
.alto_valor_contanier .heading {
  width: 100%;
  color: white;
  padding: 50px 20px;
}
.alto_valor_contanier .heading h3,
.alto_valor_contanier .heading p {
  margin: 0;
}
.alto_valor_contanier .underlined {
  border-color: #ed3237;
}

.product_block_alto_valor img {
  height: 200px;
}

.add_product .surface_info {
  color: #9b9b9b;
  width: 100%;
  display: block;
  margin-top: 5px;
}

.cart_page h1 {
  text-align: center;
  font-weight: normal;
  font-size: 1.5em;
}
.cart_page h3 {
  font-weight: lighter;
  font-size: 1.5em;
}
.cart_page .cart_items .headers {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.cart_page .cart_items .headers h3 {
  color: #9b9b9b;
  font-weight: normal;
  font-size: 18px;
}
.cart_page .promotions {
  display: flex;
  align-items: center;
}
.cart_page .promotions p {
  margin: 0;
}
.cart_page .promotions b {
  text-transform: uppercase;
}

.checkout_btn {
  font-size: 20px;
  position: relative;
  padding: 25px;
  color: white !important;
  text-transform: none;
  color: white !important;
  background-color: #f7a807;
}

.payment_icons {
  align-items: center;
  margin-top: -10px;
  margin-bottom: 20px;
  padding: 15px 10px;
  padding-top: 25px;
  border-radius: 0 0 20px 20px;
  background-color: #f6f5f8;
}
.payment_icons .icons {
  color: #39393b;
  font-size: 30px;
  display: flex;
  justify-content: space-around;
}
.payment_icons .icons img {
  height: 14px;
}
@media (min-width: 1000px) {
  .payment_icons .icons img {
    height: 20px;
  }
}

.products_needed p {
  font-weight: lighter;
  margin-bottom: 20px;
}

.cart_totals {
  padding: 0 5%;
}
.cart_totals .totals {
  width: 100%;
  margin-bottom: 30px;
}
.cart_totals .totals > div:first-child {
  display: flex;
  justify-content: space-between;
}
.cart_totals .totals .promotions_totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cart_totals .totals h5 {
  font-weight: lighter;
}
.cart_totals .totals h5.total {
  font-weight: bold;
}
.cart_totals .totals h6 {
  font-size: 17px;
  margin: 0;
  color: tomato;
}
.cart_totals .ctas {
  width: 100%;
}

.ticket {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  background: #fbfbfb;
  box-shadow: 2px 2px 15px 0px #9b9b9b;
}
.ticket:before, .ticket:after {
  content: "";
  position: absolute;
  left: 5px;
  height: 6px;
  width: 290px;
}
.ticket:before {
  top: -5px;
  background: radial-gradient(circle, transparent, transparent 50%, #fbfbfb 50%, #fbfbfb 100%) -7px -8px/16px 16px repeat-x;
}
.ticket:after {
  bottom: -5px;
  background: radial-gradient(circle, transparent, transparent 50%, #fbfbfb 50%, #fbfbfb 100%) -7px -2px/16px 16px repeat-x;
}
.ticket .header {
  display: flex;
  justify-content: space-between;
}
.ticket .header h4 {
  font-size: 1em;
}
.ticket .header p {
  font-size: 0.7em;
}
.ticket__content {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 6px solid #d8d8d8;
}
.ticket__text {
  width: 400px;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #c6dede;
  transform: translate(-25px, 25px) rotate(-55deg);
}
.ticket_ul li {
  display: flex;
  align-items: center !important;
  margin: 15px 0;
}
.ticket_ul li .media-body {
  font-size: 15px;
}
.ticket_ul li .media-body span {
  color: #9b9b9b;
}
.ticket_ul li i {
  font-size: xx-large;
}

.cart_row {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.cart_row:last-child {
  border-bottom: none;
}

.checkout_page {
  margin-top: 15px;
}
.checkout_page .checkout_header img {
  width: 180px;
  margin-bottom: 15px;
  display: block;
}
.checkout_page h1 {
  font-weight: lighter;
}
.checkout_page .totals {
  padding: 20px;
}
.checkout_page .totals p {
  display: flex;
  justify-content: space-between;
}
.checkout_page .card h4 {
  margin: 0;
}
.checkout_page .no_delivery button {
  line-height: 1.3;
}

.footer_checkout {
  background-color: #e6e6e6;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.footer_checkout .legal {
  width: 100%;
  text-align: right;
  padding-right: 100px;
}
.footer_checkout .legal a {
  margin: 15px;
}
.footer_checkout a {
  color: #262627;
  font-size: small;
}

.user_email {
  width: 10 0%;
  margin-bottom: 50px;
}

.branch_secion .btn_rich_container {
  flex-wrap: wrap;
}
.branch_secion .btn_rich_container .btn_rich {
  padding: 20px;
  min-width: 200px;
  border-radius: 5px;
  flex: 1 1;
  margin: 15px;
}

.payment_section .btn_rich_container {
  flex-wrap: wrap;
}
.payment_section .btn_rich_container .btn_rich {
  position: relative;
  height: auto;
  flex-basis: calc(15% - 20px); /* 25px is the combined width of border and padding */
  margin: 10px 15px;
  margin-left: 0px;
  padding: 10px;
}
.payment_section .btn_rich_container .btn_rich img {
  width: 90px;
  margin: 15px;
}
.payment_section .btn_rich_container .btn_rich p {
  position: absolute;
  bottom: 3px;
  opacity: 0.8;
}
.payment_section .btn_rich_container .btn_rich:first-child {
  margin-right: 15px;
}
.payment_section .btn_rich_container .btn_rich.disabled {
  pointer-events: none;
}

.input_btns {
  position: relative;
  padding-right: 15px;
}
.input_btns input {
  padding-right: 55px;
}
.input_btns .btn_toggles {
  display: flex;
  right: 15px;
  top: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 55px;
  position: absolute;
}
.input_btns .btn_toggles .btn {
  min-width: unset;
  padding: 5px;
  color: #9b9b9b;
}
.input_btns .btn_toggles .btn:hover {
  color: #3e78b2;
}
.input_btns .btn_toggles .btn.disabled {
  color: #e6e6e6;
}
.input_btns .btn_toggles .btn.disabled:hover {
  color: #e6e6e6;
}

.shipping_cost h5 {
  font-weight: normal;
}
.shipping_cost h4 {
  font-size: 20px;
  font-weight: normal;
}

.delivery-info {
  border: 2px solid #ef233c;
  background-color: #edf2f4;
  padding: 10px;
  color: #2b2d42;
  border-radius: 10px;
  font-size: 80%;
}

.static h1 {
  font-size: 2em;
  margin-bottom: 20px;
}
.static p {
  font-size: 1.2em;
}
.static ul {
  list-style: initial;
  padding-left: 5em;
}
.static ul li {
  font-size: 1.2em;
}
.static .content .blocks-gallery-grid li {
  display: inline-block;
  margin-right: 15px;
}
.static .content figure img {
  max-width: 100%;
  height: auto;
}
.static .content figure.aligncenter {
  display: flex;
  justify-content: center;
}

.filters {
  width: 150px;
  margin-right: 175px;
}
.filters .filter_container {
  max-height: 145px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.filters .filter_container.expanded {
  max-height: 1000px;
}
@media screen and (max-width: 767px) {
  .filters {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    width: auto;
    margin-right: 0;
  }
}

.account_page .material-shadows > .card-body, .account_page .main_navbar .navbar_items a.navbar-brand.alto_valor > .card-body, .main_navbar .navbar_items .account_page a.navbar-brand.alto_valor > .card-body,
.account_page .main_navbar .navbar_items button.navbar-brand.alto_valor > .card-body,
.main_navbar .navbar_items .account_page button.navbar-brand.alto_valor > .card-body, .account_page .main_navbar .navbar_items a.navbar-brand.eco_friendly > .card-body, .main_navbar .navbar_items .account_page a.navbar-brand.eco_friendly > .card-body,
.account_page .main_navbar .navbar_items button.navbar-brand.eco_friendly > .card-body,
.main_navbar .navbar_items .account_page button.navbar-brand.eco_friendly > .card-body, .account_page .alto_valor_contanier .container > .card-body, .alto_valor_contanier .account_page .container > .card-body, .account_page .payment_section .btn_rich_container .btn_rich > .card-body, .payment_section .btn_rich_container .account_page .btn_rich > .card-body {
  min-height: 290px;
}
.account_page .material-shadows > .card-body.empty, .account_page .main_navbar .navbar_items a.navbar-brand.alto_valor > .card-body.empty, .main_navbar .navbar_items .account_page a.navbar-brand.alto_valor > .card-body.empty,
.account_page .main_navbar .navbar_items button.navbar-brand.alto_valor > .card-body.empty,
.main_navbar .navbar_items .account_page button.navbar-brand.alto_valor > .card-body.empty, .account_page .main_navbar .navbar_items a.navbar-brand.eco_friendly > .card-body.empty, .main_navbar .navbar_items .account_page a.navbar-brand.eco_friendly > .card-body.empty,
.account_page .main_navbar .navbar_items button.navbar-brand.eco_friendly > .card-body.empty,
.main_navbar .navbar_items .account_page button.navbar-brand.eco_friendly > .card-body.empty, .account_page .alto_valor_contanier .container > .card-body.empty, .alto_valor_contanier .account_page .container > .card-body.empty, .account_page .payment_section .btn_rich_container .btn_rich > .card-body.empty, .payment_section .btn_rich_container .account_page .btn_rich > .card-body.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order_data .header {
  display: flex;
  justify-content: space-between;
}
.order_data .header h1 {
  font-size: 20px;
  font-weight: 400;
  color: #333;
}
.order_data .order_info h5 {
  font-size: 15px;
}
.order_data .order_info p {
  margin: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.product_reviews .rate_cloumn > * {
  margin: 0;
  margin-top: 30px;
}
.product_reviews .rate_producs .btn {
  margin: 0;
  min-width: unset;
  padding: 2px;
  font-size: 20px;
  color: #ffd700;
}
.product_reviews .empty_jumbo i {
  display: inline-block;
}

.sucurales .map_container {
  position: relative;
}
.sucurales .map_container .pin_img {
  position: absolute;
  transition: all 0.3s;
}
.sucurales .map_container .pin_img:hover {
  cursor: pointer;
  transform: scale(1.5);
}
.sucurales .maps_cont {
  position: relative;
}
.sucurales .maps_cont iframe {
  position: absolute;
  z-index: 10;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.sucurales .branches_cont {
  max-height: 60vh;
  overflow-y: scroll;
}
.sucurales .branches_cont.active {
  padding-top: 250px;
}

.page_404 {
  min-height: 80vh;
  background-color: #ddd;
  text-align: center;
  padding: 50px;
}
.page_404 .icon {
  opacity: 0.5;
}

.idea-container {
  padding: 0;
  margin-bottom: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  /* Special classes for larger images to add visual interest */
  /* Example for adding hover effect */
}
.idea-container .details {
  padding: 20px;
}
.idea-container .images_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 15px;
  margin: 20px;
}
.idea-container .images_grid img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px; /* Rounded corners for a softer look */
}
.idea-container .images_grid .large {
  grid-column: span 2;
  grid-row: span 2;
}
.idea-container .images_grid img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.idea-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.idea-image {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.product-list {
  list-style: none;
  padding: 0;
}

.product-list-item {
  margin-bottom: 5px;
  font-size: 14px;
}

.product-link {
  color: #007bff;
  text-decoration: none;
}
.product-link:hover {
  text-decoration: underline;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.designmathasa a {
  color: #23747d;
}
.designmathasa .footer {
  background-color: #e2e2e2;
}
.designmathasa .footer .footer-info h3,
.designmathasa .footer .footer-info h2,
.designmathasa .footer .footer-info p {
  color: #262627;
}
.designmathasa .footer .footer-info a,
.designmathasa .footer .footer-info button {
  color: #262627;
}
.designmathasa .footer .footer-info a.active,
.designmathasa .footer .footer-info button.active {
  color: #23747d;
}
.designmathasa .navbar_items a.navbar-brand.alto_valor,
.designmathasa .navbar_items button.navbar-brand.alto_valor {
  color: #262627;
  font-size: 17px;
  border-radius: 0;
  background: none;
  padding: 0 5px;
  box-shadow: none;
}
.designmathasa .top_nav {
  background-color: #a8d6e5;
}
.designmathasa .top_nav a,
.designmathasa .top_nav span,
.designmathasa .top_nav button {
  color: #262627;
}
.designmathasa .btn-cart {
  background-color: #23747d;
  color: white !important;
}
.designmathasa .btn-cart:hover {
  background-color: rgb(29.421875, 97.5125, 105.078125) !important;
}
.designmathasa .btn-outline-cart {
  border: 1px solid #23747d;
  color: #23747d;
}
.designmathasa .btn-outline-cart:hover {
  background-color: #23747d;
  color: white !important;
}
.designmathasa .page-link {
  color: #23747d;
}
.designmathasa .page-link:hover {
  color: rgb(29.421875, 97.5125, 105.078125);
}
.designmathasa .page-item.active .page-link {
  background-color: #23747d;
  border-color: #23747d;
}
.designmathasa .underlined {
  border-bottom: 3px solid #23747d;
}
.designmathasa .badge_main,
.designmathasa .badge_main.bold {
  background-color: #a8d6e5;
  color: #23747d;
}
.designmathasa .btn-outline-danger {
  border: 1px solid #df7a5f;
  color: #df7a5f;
}
.designmathasa .btn-outline-danger:hover {
  background-color: #df7a5f;
  color: white;
}

@media (max-width: 575px) {
  body {
    margin-bottom: 875px;
  }
  .footer {
    height: 875px;
  }
  .main_background {
    padding-top: 90px;
  }
  .xs_hidden {
    display: none;
  }
  .xs_block {
    display: block;
    width: 100%;
  }
  .xs_hidden_opacity {
    opacity: 0 !important;
  }
  .w_100_xs {
    width: 100%;
  }
  .features-section .nav {
    margin-bottom: 20px;
  }
  .features-section .nav li {
    width: 100%;
  }
  .features-section .nav li button.nav-link {
    text-align: center;
    width: 100%;
    padding: 20px;
    display: block;
    border: 2px solid white;
  }
  .features-section .nav li button.nav-link.active {
    border: 2px solid #f8cf00;
  }
  .btn_rich_container.mob_full {
    flex-direction: column;
  }
  .btn_rich_container.mob_full > * {
    flex: 1 1;
  }
  .btn_rich_container.mob_full > *:first-child {
    margin-right: 0px;
  }
  .btn_rich_container.mob_full > *:last-child {
    margin-left: 0;
  }
  .btn_rich_container.mob_full > *:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .product_block_list, .product_block_alto_valor {
    width: 255px;
  }
}
@media (min-width: 992px) {
  .hero_container .finder_select {
    margin: 0 0;
    max-width: 30%;
  }
  .featured {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "main main  side side" "main main one two" "sidel sidel sider sider";
  }
  .featured .background_img:nth-child(1) {
    grid-area: main;
  }
  .featured .background_img:nth-child(2) {
    grid-area: side;
  }
  .featured .background_img:nth-child(3) {
    grid-area: one;
  }
  .featured .background_img:nth-child(4) {
    grid-area: two;
  }
  .featured .background_img:nth-child(5) {
    grid-area: sidel;
  }
  .featured .background_img:nth-child(6) {
    grid-area: sider;
  }
  .product_block {
    max-width: 33%;
  }
  .product_modal {
    width: 80%;
  }
  .product_modal .body {
    height: 80vh;
    grid-template-rows: 2fr 1fr;
    grid-template-areas: "reco reco cart " "reco reco cart " "reco reco cta ";
  }
  .product_modal .body .related_products {
    grid-area: reco;
  }
  .product_modal .body .products {
    grid-area: cart;
  }
  .product_modal .body .foot {
    grid-area: cta;
  }
  .user_email {
    width: 70%;
  }
  .cart_row .products {
    flex: 5;
  }
  .cart_row .price {
    flex: 2;
  }
  .cart_row .quanty {
    flex: 2;
  }
  .cart_row .del {
    flex: 1;
  }
}
@media (max-width: 768px) {
  .product_block_list, .product_block_alto_valor {
    width: 50%;
  }
}
.no_padding {
  padding: 0 !important;
}

.no_padding_top {
  padding-top: 0 !important;
}

.no_padding_bottom {
  padding-bottom: 0 !important;
}

.no_padding_left {
  padding-left: 0 !important;
}

.no_padding_rigth {
  padding-right: 0 !important;
}

.no_margin {
  margin: 0 !important;
}

.no_user_select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.default_margin_top {
  margin-top: 15px;
}

.default_margin_bottom {
  margin-bottom: 15px;
}

